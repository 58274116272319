import CryptoJS from 'crypto-js'
import moment from 'moment'
import store from '@/store/index'

export const encryptData = (word) => {
	const key = CryptoJS.enc.Utf8.parse('8080808080808080')
	const iv = CryptoJS.enc.Utf8.parse('8080808080808080')
	const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(word.toString()), key, {
		keySize: 128 / 8,
		iv: iv,
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC
	})
	return encrypted.toString()
}

export function getDistance(point1, point2) {
	const R = 6371e3 // metres
	const f1 = point1.lat * Math.PI / 180 // φ, λ in radians
	const f2 = point2.lat * Math.PI / 180
	const a1 = (point2.lat - point1.lat) * Math.PI / 180
	const a2 = (point2.lng - point1.lng) * Math.PI / 180

	const a = Math.sin(a1 / 2) * Math.sin(a1 / 2) +
		Math.cos(f1) * Math.cos(f2) *
		Math.sin(a2 / 2) * Math.sin(a2 / 2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

	return parseInt(R * c) || 0 // in metres
}

export function replaceFlags(string, values) {
	if (values) {
		for (let i = 0; i < values.length; i++) {
			string = string.replace('%s', values[i])
		}
	}
	return string
}

export function formatPrice(price) {
	const number = parseInt(price) === price ? price : parseFloat(price).toFixed(2)
	return String(number).replace(/(\d)(?=(\d{3})+\b)/g, `$1 `)
}

export function formatDate(date, locale, format) {
	if (!format) {
		format = moment(date).format('YYYY') === moment().format('YYYY') ? 'ddd, DD MMM @ HH:mm' : 'ddd, DD MMM, YYYY @ HH:mm'
	}
	return locale ? moment(date).locale(locale).format(format) : moment(date).format(format)
}

export function getProjectType(id) {
	if (store.state.common.projectTypes) {
		return store.state.common.projectTypes.find(
			t => t.projectTypeId === id
		)
	} else {
		return ''
	}
}

export function getFileAccessType(id) {
	if (store.state.common.fileAccessTypes) {
		return store.state.common.fileAccessTypes.find(
			t => t.fileAccessTypeId === id
		)
	} else {
		return ''
	}
}

export function getCityModule(id) {
	if (store.state.common.cityModules) {
		return store.state.common.cityModules.find(
			t => t.cityModuleId === id
		)
	} else {
		return ''
	}
}
